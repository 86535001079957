<template>
  <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
    <div class="text-900 font-bold text-6xl mb-4 text-center">
      Compra de SMS
    </div>
    <!--<div class="text-700 text-xl mb-6 text-center line-height-3"></div>-->

    <div class="grid" v-if="!loading.plans">
      <div
        class="col-12 md:col-6 lg:col-3"
        v-for="plan in plans"
        :key="plan.amount"
      >
        <div class="p-3 h-full">
          <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
          >
            <div class="text-900 font-medium text-xl mb-2">
              {{ plan.amount }} sms
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900"
                >{{ plan.price }}€ ({{ round(plan.price / plan.amount) }}€ /
                sms)</span
              >
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Añade {{ plan.amount }} sms más</span>
              </li>
            </ul>
            <hr
              class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto"
            />
            <Button
              label="Comprar"
              class="'p-3 w-full"
              :disabled="loading.payment"
              @click="buySms(plan)"
            ></Button>
          </div>
        </div>
      </div>
    </div>
    <ProgressSpinner v-else />
  </div>
</template>

<script>
import api from '../api/Acutame';
import { round } from '../utils/util';

export default {
  mounted() {
    this.getPlans();
  },
  data() {
    return {
      user: this.$store.state.user,
      loading: {
        payment: false,
        plans: true,
      },
      plans: [],
      additionalOrganizations: 0,
    };
  },
  methods: {
    round(amount) {
      return round(amount, 2);
    },
    async buySms(plan) {
      this.loading.sms = true;
      const { url } = await api.buySms(plan.amount);
      window.location.assign(url);
      this.loading.payment = false;
    },
    async getPlans() {
      this.loading.plans = true;
      const packs = await api.getPricingSms();
      const plans = Object.entries(packs).map(([amount, price]) => ({
        amount,
        price,
      }));
      this.plans = plans;
      this.loading.plans = false;
    },
  },
};
</script>
